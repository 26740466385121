const API_URL_IMAGES = process.env.VUE_APP_API_URL_IMAGES;
// eslint-disable-line no-process-env
const BadgeDesign = {
  name: '',
  currentLayout: 'Badge',
  repeatedAttributes: false,
  idFront: 1,
  components: [
    {
      id: 0,
      type: 'qrcode',
      templateSide: 'front',
      x: 94.4,
      y: 92.4,
      isDraggable: false,
    },
    {
      id: 1,
      type: 'image',
      templateSide: 'front',
      src: require('@/components/TemplateEditor/assets/image-yourlogohere1.png'),
      x: 50,
      y: 70,
      size: 0.2,
      isDraggable: false,
      isEditable: false,
    },
    {
      id: 2,
      type: 'text',
      templateSide: 'front',
      text: '[PLACEHOLDER1]',
      justification: 'center',
      fontFamily: 'Montserrat',
      fontSize: 28,
      isBold: true,
      isItalic: false,
      isUnderlined: false,
      fontColor: 'white',
      x: 50,
      y: 22,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      // warp: 145,
      // spacing: -18,
      maxCharacters: 16,
    },
    {
      id: 3,
      type: 'text',
      templateSide: 'front',
      text: '[PLACEHOLDER2]',
      justification: 'center',
      fontFamily: 'Montserrat',
      fontSize: 42,
      isBold: true,
      isItalic: false,
      isUnderlined: false,
      fontColor: '#ffffff',
      x: 50,
      y: 50,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      maxCharacters: 16,
    },
    {
      id: 4,
      type: 'text',
      templateSide: 'front',
      text: '[PLACEHOLDER3]',
      justification: 'center',
      fontFamily: 'Montserrat',
      fontSize: 18,
      isBold: true,
      isItalic: false,
      isUnderlined: false,
      fontColor: 'white',
      x: 50,
      y: 85,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      maxCharacters: 22,
    },
  ],
  backgroundFront: `${API_URL_IMAGES}/backgrounds/badge-background.png`,
  backgroundBack: '',
};

const CertificateDesign = {
  name: '',
  currentLayout: 'A4',
  repeatedAttributes: false,
  idFront: 1,
  components: [
    {
      id: 0,
      type: 'qrcode',
      templateSide: 'front',
      x: 95.4,
      y: 92.4,
      isDraggable: false,
    },
    {
      id: 1,
      type: 'image',
      templateSide: 'front',
      src: require('@/components/TemplateEditor/assets/image-yourlogohere1.png'),
      x: 50,
      y: 19,
      size: 0.2,
      isDraggable: false,
      isEditable: false,
    },
    {
      id: 2,
      type: 'text',
      templateSide: 'front',
      text: '[NAME]',
      justification: 'right',
      fontFamily: 'Montserrat',
      fontSize: 22,
      isBold: true,
      isItalic: false,
      isUnderlined: false,
      fontColor: '#343689',
      x: 50,
      y: 32,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      maxCharacters: 28,
    },
    {
      id: 3,
      type: 'text',
      templateSide: 'front',
      text: '[PLACEHOLDER2]',
      justification: 'center',
      fontFamily: 'Montserrat',
      fontSize: 18,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: '#343689',
      x: 50,
      y: 46,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      maxCharacters: 52,
    },
    {
      id: 4,
      type: 'text',
      templateSide: 'front',
      text: '[PLACEHOLDER3]',
      justification: 'center',
      fontFamily: 'Montserrat',
      fontSize: 36,
      isBold: true,
      isItalic: false,
      isUnderlined: false,
      fontColor: '#343689',
      x: 50,
      y: 56,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      maxCharacters: 28,
    },
    {
      id: 5,
      type: 'text',
      templateSide: 'front',
      text: '[PLACEHOLDER4]',
      justification: 'center',
      fontFamily: 'Montserrat',
      fontSize: 18,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: '#343689',
      x: 50,
      y: 71,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      maxCharacters: 65,
    },
    {
      id: 6,
      type: 'text',
      templateSide: 'front',
      text: '[PLACEHOLDER5]',
      justification: 'center',
      fontFamily: 'Montserrat',
      fontSize: 18,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: '#343689',
      x: 33,
      y: 86,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      maxCharacters: 20,
    },
    {
      id: 7,
      type: 'text',
      templateSide: 'front',
      text: '[PLACEHOLDER6]',
      justification: 'center',
      fontFamily: 'Montserrat',
      fontSize: 18,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: '#343689',
      x: 66,
      y: 86,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      maxCharacters: 20,
    },
  ],
  backgroundFront: `${API_URL_IMAGES}/backgrounds/certificate-background.png`,
  backgroundBack: '',
};

const CardDesign = {
  name: '',
  repeatedAttributes: false,
  currentLayout: 'Card',
  design: 'Card',
  idFront: 1,
  components: [
    {
      id: 0,
      type: 'qrcode',
      templateSide: 'front',
      x: 95.4,
      y: 92.4,
      isDraggable: false,
    },
    {
      id: 1,
      type: 'image',
      dynamicImage: true,
      text: '[IMAGE]',
      templateSide: 'front',
      src: require('@/assets/credential-design/image-yourlogohere-white.png'),
      x: 14.5,
      y: 20,
      size: 0.2,
      isMandatory: true,
      isDraggable: false,
      isEditable: false,
    },
    {
      id: 2,
      type: 'text',
      templateSide: 'front',
      text: '[NAME]',
      fontFamily: 'Montserrat',
      fontSize: 38,
      isBold: true,
      isItalic: false,
      isUnderlined: false,
      fontColor: 'white',
      x: 18,
      y: 40,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      maxCharacters: 28,
      justification: 'left',
    },
    {
      id: 3,
      type: 'text',
      templateSide: 'front',
      text: '[EMAIL]',
      justification: 'center',
      fontFamily: 'Montserrat',
      fontSize: 18,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: 'white',
      x: 19.0,
      y: 62,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      maxCharacters: 42,
      visible: false,
    },
    {
      id: 4,
      type: 'text',
      templateSide: 'front',
      text: '[DATEOFBIRTH]',
      justification: 'left',
      fontFamily: 'Montserrat',
      fontSize: 18,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: 'white',
      x: 18,
      y: 82,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      maxCharacters: 26,
    },
    {
      id: 5,
      type: 'text',
      templateSide: 'front',
      text: '[ADDRESS]',
      justification: 'left',
      fontFamily: 'Montserrat',
      fontSize: 18,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: 'white',
      x: 15,
      y: 55,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      maxCharacters: 40,
    },
    {
      id: 6,
      type: 'text',
      templateSide: 'front',
      text: '[COUNTRY]',
      justification: 'left',
      fontFamily: 'Montserrat',
      fontSize: 18,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: 'white',
      x: 15,
      y: 70,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      maxCharacters: 26,
    },
    {
      id: 7,
      type: 'text',
      templateSide: 'front',
      text: '[LICENSECLASS]',
      fontFamily: 'Montserrat',
      fontSize: 18,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: 'white',
      x: 80,
      y: 53,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      maxCharacters: 26,
      justification: 'right',
    },
    {
      id: 8,
      type: 'text',
      templateSide: 'front',
      text: '[LICENSENUMBER]',
      justification: 'right',
      fontFamily: 'Montserrat',
      fontSize: 18,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: 'white',
      x: 80,
      y: 43,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      maxCharacters: 26,
    },
    {
      id: 9,
      type: 'text',
      templateSide: 'front',
      text: '[EXPIRYDATE]',
      fontFamily: 'Montserrat',
      fontSize: 18,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: 'white',
      x: 80,
      y: 64,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      maxCharacters: 26,
      justification: 'right',
    },
    {
      id: 10,
      type: 'text',
      templateSide: 'front',
      text: '[PHONENUMBER]',
      justification: 'center',
      fontFamily: 'Montserrat',
      fontSize: 18,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: 'white',
      x: 72,
      y: 76,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      maxCharacters: 26,
      visible: false,
    },
    {
      id: 11,
      type: 'text',
      templateSide: 'front',
      text: '[NATIONALITY]',
      justification: 'center',
      fontFamily: 'Montserrat',
      fontSize: 18,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: 'white',
      x: 72,
      y: 76,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      maxCharacters: 26,
      visible: false,
    },
    {
      id: 12,
      type: 'image',
      templateSide: 'front',
      src: require('@/assets/credential-design/yourlogo-transparent.png'),
      x: 85,
      y: 20,
      size: 0.2,
      isMandatory: true,
      isDraggable: false,
      isEditable: false,
    },
    {
      id: 13,
      type: 'text',
      templateSide: 'front',
      text: 'Nombre  ',
      justification: 'center',
      fontFamily: 'Montserrat',
      fontSize: 16,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: '#ffffff',
      x: 13,
      y: 35,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      warp: 0,
      spacing: 0,
      visible: true,
    },
    {
      id: 14,
      type: 'text',
      templateSide: 'front',
      text: 'Dirección',
      justification: 'center',
      fontFamily: 'Montserrat',
      fontSize: 16,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: '#ffffff',
      x: 13,
      y: 50,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      warp: 0,
      spacing: 0,
      visible: true,
    },
    {
      id: 15,
      type: 'text',
      templateSide: 'front',
      text: 'País',
      justification: 'center',
      fontFamily: 'Montserrat',
      fontSize: 16,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: '#ffffff',
      x: 10,
      y: 64,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      warp: 0,
      spacing: 0,
      visible: true,
    },
    {
      id: 16,
      type: 'text',
      templateSide: 'front',
      text: 'Fecha de nacimiento',
      justification: 'center',
      fontFamily: 'Montserrat',
      fontSize: 16,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: '#ffffff',
      x: 20,
      y: 77,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      warp: 0,
      spacing: 0,
      visible: true,
    },
    {
      id: 17,
      type: 'text',
      templateSide: 'front',
      text: 'Clase de licencia',
      justification: 'center',
      fontFamily: 'Montserrat',
      fontSize: 16,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: '#000000',
      x: 87,
      y: 49,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      warp: 0,
      spacing: 0,
      visible: true,
    },
    {
      id: 18,
      type: 'text',
      templateSide: 'front',
      text: 'Nº de doc. fisico',
      justification: 'center',
      fontFamily: 'Montserrat',
      fontSize: 16,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: '#000000',
      x: 87,
      y: 38,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      warp: 0,
      spacing: 0,
      visible: true,
    },
    {
      id: 19,
      type: 'text',
      templateSide: 'front',
      text: 'Vencimiento',
      justification: 'center',
      fontFamily: 'Montserrat',
      fontSize: 16,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: '#000000',
      x: 89,
      y: 60,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      warp: 0,
      spacing: 0,
      visible: true,
    },
  ],
  layoutBackgroundColor: '#29969E',
  backgroundFront: `${API_URL_IMAGES}/backgrounds/card-background.png`,
  backgroundBack: '',
};

const CardDesignES = {
  name: '',
  repeatedAttributes: false,
  currentLayout: 'Card',
  design: 'Card',
  idFront: 1,
  components: [
    {
      id: 0,
      type: 'qrcode',
      templateSide: 'front',
      x: 95.4,
      y: 92.4,
      isDraggable: false,
    },
    {
      id: 1,
      type: 'image',
      dynamicImage: true,
      text: '[IMAGE]',
      templateSide: 'front',
      src: require('@/assets/credential-design/image-yourlogohere-white.png'),
      x: 14.5,
      y: 20,
      size: 0.2,
      isMandatory: true,
      isDraggable: false,
      isEditable: false,
    },
    {
      id: 2,
      type: 'text',
      templateSide: 'front',
      text: '[NOMBRE]',
      fontFamily: 'Montserrat',
      fontSize: 38,
      isBold: true,
      isItalic: false,
      isUnderlined: false,
      fontColor: 'white',
      x: 20,
      y: 40,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      maxCharacters: 28,
      justification: 'left',
    },
    {
      id: 3,
      type: 'text',
      templateSide: 'front',
      text: '[ENDEREZOELECTRONICO]',
      justification: 'center',
      fontFamily: 'Montserrat',
      fontSize: 18,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: 'white',
      x: 19.0,
      y: 62,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      maxCharacters: 42,
      visible: false,
    },
    {
      id: 4,
      type: 'text',
      templateSide: 'front',
      text: '[FECHADENACIMIENTO]',
      justification: 'left',
      fontFamily: 'Montserrat',
      fontSize: 18,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: 'white',
      x: 18,
      y: 82,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      maxCharacters: 26,
    },
    {
      id: 5,
      type: 'text',
      templateSide: 'front',
      text: '[DIRECCION]',
      justification: 'left',
      fontFamily: 'Montserrat',
      fontSize: 18,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: 'white',
      x: 16,
      y: 55,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      maxCharacters: 40,
    },
    {
      id: 6,
      type: 'text',
      templateSide: 'front',
      text: '[PAIS]',
      justification: 'left',
      fontFamily: 'Montserrat',
      fontSize: 18,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: 'white',
      x: 10,
      y: 70,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      maxCharacters: 26,
    },
    {
      id: 7,
      type: 'text',
      templateSide: 'front',
      text: '[CLASEDELICENCIA]',
      fontFamily: 'Montserrat',
      fontSize: 18,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: 'white',
      x: 80,
      y: 53,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      maxCharacters: 26,
      justification: 'right',
    },
    {
      id: 8,
      type: 'text',
      templateSide: 'front',
      text: '[NUMERODELICENCIA]',
      justification: 'right',
      fontFamily: 'Montserrat',
      fontSize: 18,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: 'white',
      x: 76,
      y: 43,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      maxCharacters: 26,
    },
    {
      id: 9,
      type: 'text',
      templateSide: 'front',
      text: '[FECHADEVENCIMIENTO]',
      fontFamily: 'Montserrat',
      fontSize: 18,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: 'white',
      x: 74,
      y: 64,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      maxCharacters: 26,
      justification: 'right',
    },
    {
      id: 10,
      type: 'text',
      templateSide: 'front',
      text: '[TELEFONO]',
      justification: 'center',
      fontFamily: 'Montserrat',
      fontSize: 18,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: 'white',
      x: 72,
      y: 76,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      maxCharacters: 26,
      visible: false,
    },
    {
      id: 11,
      type: 'text',
      templateSide: 'front',
      text: '[NACIONALIDAD]',
      justification: 'center',
      fontFamily: 'Montserrat',
      fontSize: 18,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: 'white',
      x: 72,
      y: 76,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      maxCharacters: 26,
      visible: false,
    },
    {
      id: 12,
      type: 'image',
      templateSide: 'front',
      src: require('@/assets/credential-design/yourlogo-transparent.png'),
      x: 85,
      y: 20,
      size: 0.2,
      isMandatory: true,
      isDraggable: false,
      isEditable: false,
    },
    {
      id: 13,
      type: 'text',
      templateSide: 'front',
      text: 'Nombre  ',
      justification: 'center',
      fontFamily: 'Montserrat',
      fontSize: 16,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: '#ffffff',
      x: 13,
      y: 35,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      warp: 0,
      spacing: 0,
      visible: true,
    },
    {
      id: 14,
      type: 'text',
      templateSide: 'front',
      text: 'Dirección',
      justification: 'center',
      fontFamily: 'Montserrat',
      fontSize: 16,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: '#ffffff',
      x: 13,
      y: 50,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      warp: 0,
      spacing: 0,
      visible: true,
    },
    {
      id: 15,
      type: 'text',
      templateSide: 'front',
      text: 'País',
      justification: 'center',
      fontFamily: 'Montserrat',
      fontSize: 16,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: '#ffffff',
      x: 10,
      y: 64,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      warp: 0,
      spacing: 0,
      visible: true,
    },
    {
      id: 16,
      type: 'text',
      templateSide: 'front',
      text: 'Fecha de nacimiento',
      justification: 'center',
      fontFamily: 'Montserrat',
      fontSize: 16,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: '#ffffff',
      x: 20,
      y: 77,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      warp: 0,
      spacing: 0,
      visible: true,
    },
    {
      id: 17,
      type: 'text',
      templateSide: 'front',
      text: 'Clase de licencia',
      justification: 'center',
      fontFamily: 'Montserrat',
      fontSize: 16,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: '#000000',
      x: 87,
      y: 49,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      warp: 0,
      spacing: 0,
      visible: true,
    },
    {
      id: 18,
      type: 'text',
      templateSide: 'front',
      text: 'Nº de doc. fisico',
      justification: 'center',
      fontFamily: 'Montserrat',
      fontSize: 16,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: '#000000',
      x: 87,
      y: 38,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      warp: 0,
      spacing: 0,
      visible: true,
    },
    {
      id: 19,
      type: 'text',
      templateSide: 'front',
      text: 'Vencimiento',
      justification: 'center',
      fontFamily: 'Montserrat',
      fontSize: 16,
      isBold: false,
      isItalic: false,
      isUnderlined: false,
      fontColor: '#000000',
      x: 89,
      y: 60,
      isMandatory: true,
      isEditable: false,
      isDraggable: false,
      warp: 0,
      spacing: 0,
      visible: true,
    },
  ],
  layoutBackgroundColor: '#29969E',
  backgroundFront: `${API_URL_IMAGES}/backgrounds/card-background.png`,
  backgroundBack: '',
};

export { BadgeDesign, CertificateDesign, CardDesign, CardDesignES };
